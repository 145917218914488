var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [!_vm.showOnly ? _c('Row', {
    staticClass: "operation"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "icon": "md-add"
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("添加文件夹")]), _c('Dropdown', {
    on: {
      "on-click": _vm.handleTreeDropdown
    }
  }, [_c('Button', [_vm._v(" 更多操作 "), _c('Icon', {
    attrs: {
      "type": "md-arrow-dropdown"
    }
  })], 1), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', {
    attrs: {
      "name": "edit",
      "disabled": _vm.selectNode.id == 0 || _vm.selectNode.id == -1
    }
  }, [_vm._v("编辑文件夹")]), _c('DropdownItem', {
    attrs: {
      "name": "del",
      "disabled": _vm.selectNode.id == 0 || _vm.selectNode.id == -1
    }
  }, [_vm._v("删除文件夹")]), _c('DropdownItem', {
    attrs: {
      "name": "refresh"
    }
  }, [_vm._v("刷新")])], 1)], 1)], 1) : _vm._e(), !_vm.showOnly ? _c('Alert', {
    attrs: {
      "show-icon": ""
    }
  }, [_vm._v(" 当前选择： "), _c('span', {
    staticClass: "select-title"
  }, [_vm._v(_vm._s(_vm.editTitle))]), _vm.editTitle && _vm.selectNode.id != '0' ? _c('a', {
    staticClass: "select-clear",
    on: {
      "click": _vm.cancelEdit
    }
  }, [_vm._v("取消选择")]) : _vm._e()]) : _vm._e(), _c('Input', {
    attrs: {
      "suffix": "ios-search",
      "placeholder": "输入搜索文件夹名称",
      "clearable": ""
    },
    on: {
      "on-change": _vm.search
    },
    model: {
      value: _vm.searchKey,
      callback: function ($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  }), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "tree-bar",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('Tree', {
    ref: "tree",
    attrs: {
      "data": _vm.data,
      "load-data": _vm.loadData,
      "render": _vm.renderContent
    },
    on: {
      "on-check-change": _vm.changeSelect,
      "on-select-change": _vm.selectTree
    }
  })], 1), _vm.loading ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _vm._e()], 1), _c('Modal', {
    attrs: {
      "title": _vm.modalTitle,
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "label-width": 85,
      "rules": _vm.formValidate
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "上级节点："
    }
  }, [_vm._v(_vm._s(_vm.form.parentTitle) + " "), _c('Tooltip', {
    staticStyle: {
      "display": "inline-block !important"
    },
    attrs: {
      "content": _vm.selectNode.id == '0' ? '若需添加子目录，请先点击选择一个文件夹' : '若需添加一级目录，请先取消选择当前文目录',
      "placement": "right",
      "transfer": "",
      "max-width": "290"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-help-circle",
      "size": "20",
      "color": "#c5c5c5"
    }
  })], 1)], 1), _c('FormItem', {
    attrs: {
      "label": "文件夹名",
      "prop": "title"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "排序值",
      "prop": "sortOrder"
    }
  }, [_c('Tooltip', {
    attrs: {
      "trigger": "hover",
      "placement": "right",
      "content": "值越小越靠前，支持小数"
    }
  }, [_c('InputNumber', {
    attrs: {
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.form.sortOrder,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sortOrder", $$v);
      },
      expression: "form.sortOrder"
    }
  })], 1)], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.handelSubmit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "search"
  }, [_vm.showTrash ? _c('trash', {
    on: {
      "close": function ($event) {
        _vm.showTrash = false;
      }
    }
  }) : _vm._e(), _c('Card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showTrash,
      expression: "!showTrash"
    }]
  }, [_c('Row', {
    attrs: {
      "type": "flex",
      "justify": "space-between"
    }
  }, [_c('Col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.expand,
      expression: "expand"
    }],
    attrs: {
      "span": "5"
    }
  }, [_c('fileCategory', {
    on: {
      "on-select": _vm.selectCategory
    }
  })], 1), _c('div', {
    staticClass: "expand"
  }, [_c('Icon', {
    staticClass: "icon",
    attrs: {
      "type": _vm.expandIcon,
      "size": "16"
    },
    on: {
      "click": _vm.changeExpand
    }
  })], 1), _c('Col', {
    attrs: {
      "span": _vm.span
    }
  }, [_c('Row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openSearch,
      expression: "openSearch"
    }],
    nativeOn: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSearch.apply(null, arguments);
      }
    }
  }, [_c('Form', {
    ref: "searchForm",
    attrs: {
      "model": _vm.searchForm,
      "inline": "",
      "label-width": 70
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "文件名",
      "prop": "title"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "type": "text",
      "placeholder": "请输入文件名",
      "clearable": ""
    },
    model: {
      value: _vm.searchForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "title", $$v);
      },
      expression: "searchForm.title"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "创建时间"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "options": _vm.options,
      "type": "daterange",
      "format": "yyyy-MM-dd",
      "clearable": "",
      "placeholder": "选择起始时间"
    },
    on: {
      "on-change": _vm.selectDateRange
    },
    model: {
      value: _vm.selectDate,
      callback: function ($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1), _c('FormItem', {
    staticClass: "br",
    staticStyle: {
      "margin-left": "-35px"
    }
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "icon": "ios-search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("搜索")]), _c('Button', {
    on: {
      "click": _vm.handleReset
    }
  }, [_vm._v("重置")])], 1)], 1)], 1), _c('Row', {
    staticClass: "operation",
    attrs: {
      "align": "middle",
      "justify": "space-between"
    }
  }, [_c('div', {
    staticClass: "br"
  }, [_c('Button', {
    attrs: {
      "disabled": !_vm.searchForm.categoryId,
      "type": "primary",
      "icon": "md-cloud-upload"
    },
    on: {
      "click": function ($event) {
        _vm.uploadVisible = true;
      }
    }
  }, [_vm._v("上传文件")]), _c('Dropdown', {
    on: {
      "on-click": _vm.handleDropdown
    }
  }, [_c('Button', [_vm._v(" 更多操作 "), _c('Icon', {
    attrs: {
      "type": "md-arrow-dropdown"
    }
  })], 1), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'list',
      expression: "showType == 'list'"
    }],
    attrs: {
      "name": "moveAll"
    }
  }, [_vm._v("移动")]), _c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'list',
      expression: "showType == 'list'"
    }],
    attrs: {
      "name": "removeAll"
    }
  }, [_vm._v("批量删除")]), _c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'thumb',
      expression: "showType == 'thumb'"
    }],
    attrs: {
      "name": "title"
    }
  }, [_vm._v("原名称排序")]), _c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'thumb',
      expression: "showType == 'thumb'"
    }],
    attrs: {
      "name": "size"
    }
  }, [_vm._v("文件大小排序")]), _c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'thumb',
      expression: "showType == 'thumb'"
    }],
    attrs: {
      "name": "type"
    }
  }, [_vm._v("文件类型排序")]), _c('DropdownItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'thumb',
      expression: "showType == 'thumb'"
    }],
    attrs: {
      "name": "time"
    }
  }, [_vm._v("创建时间排序")])], 1)], 1), _c('Button', {
    attrs: {
      "type": "dashed",
      "icon": "md-trash"
    },
    on: {
      "click": function ($event) {
        _vm.showTrash = true;
      }
    }
  }, [_vm._v("回收站")])], 1), _c('div', {
    staticClass: "icons"
  }, [_c('Tooltip', {
    attrs: {
      "content": "刷新",
      "placement": "top",
      "transfer": ""
    }
  }, [_c('Icon', {
    staticClass: "item",
    attrs: {
      "type": "md-refresh",
      "size": "18"
    },
    on: {
      "click": _vm.getDataList
    }
  })], 1), _c('Tooltip', {
    attrs: {
      "content": _vm.openSearch ? '关闭搜索' : '开启搜索',
      "placement": "top",
      "transfer": ""
    }
  }, [_c('Icon', {
    staticClass: "item tip",
    attrs: {
      "type": "ios-search",
      "size": "18"
    },
    on: {
      "click": function ($event) {
        _vm.openSearch = !_vm.openSearch;
      }
    }
  })], 1), _c('Tooltip', {
    attrs: {
      "content": _vm.openTip ? '关闭提示' : '开启提示',
      "placement": "top",
      "transfer": ""
    }
  }, [_c('Icon', {
    staticClass: "item tip",
    attrs: {
      "type": "md-bulb",
      "size": "18"
    },
    on: {
      "click": function ($event) {
        _vm.openTip = !_vm.openTip;
      }
    }
  })], 1), _c('Select', {
    staticStyle: {
      "width": "150px",
      "margin": "0 25px 0 15px"
    },
    on: {
      "on-change": _vm.changeFileType
    },
    model: {
      value: _vm.fileType,
      callback: function ($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, [_c('Option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("所有文件")]), _c('Option', {
    attrs: {
      "value": "word"
    }
  }, [_vm._v("Word")]), _c('Option', {
    attrs: {
      "value": "excel"
    }
  }, [_vm._v("Excel")]), _c('Option', {
    attrs: {
      "value": "pdf"
    }
  }, [_vm._v("PDF")]), _c('Option', {
    attrs: {
      "value": "pic"
    }
  }, [_vm._v("图片")]), _c('Option', {
    attrs: {
      "value": "video"
    }
  }, [_vm._v("视频")]), _c('Option', {
    attrs: {
      "value": "audio"
    }
  }, [_vm._v("音频")]), _c('Option', {
    attrs: {
      "value": "text"
    }
  }, [_vm._v("文本")]), _c('Option', {
    attrs: {
      "value": "ppt"
    }
  }, [_vm._v("PPT")]), _c('Option', {
    attrs: {
      "value": "zip"
    }
  }, [_vm._v("ZIP")]), _c('Option', {
    attrs: {
      "value": "rar"
    }
  }, [_vm._v("RAR")])], 1), _c('RadioGroup', {
    attrs: {
      "type": "button"
    },
    on: {
      "on-change": _vm.changeShowType
    },
    model: {
      value: _vm.showType,
      callback: function ($$v) {
        _vm.showType = $$v;
      },
      expression: "showType"
    }
  }, [_c('Radio', {
    attrs: {
      "title": "列表",
      "label": "list"
    }
  }, [_c('Icon', {
    attrs: {
      "type": "md-list"
    }
  })], 1), _c('Radio', {
    attrs: {
      "title": "缩略图",
      "label": "thumb"
    }
  }, [_c('Icon', {
    attrs: {
      "type": "ios-apps"
    }
  })], 1)], 1)], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'list',
      expression: "showType == 'list'"
    }]
  }, [_c('Alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openTip,
      expression: "openTip"
    }],
    attrs: {
      "show-icon": ""
    }
  }, [_vm._v(" 已选择 "), _c('span', {
    staticClass: "select-count"
  }, [_vm._v(_vm._s(_vm.selectList.length))]), _vm._v(" 项 "), _c('a', {
    staticClass: "select-clear",
    on: {
      "click": _vm.clearSelectAll
    }
  }, [_vm._v("清空")]), _vm.selectList.length > 0 ? _c('span', {
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_vm._v("共计 " + _vm._s(_vm.totalSize) + " 存储量")]) : _vm._e()]), _c('Table', {
    ref: "table",
    attrs: {
      "loading": _vm.loading,
      "border": "",
      "columns": _vm.columns,
      "data": _vm.data,
      "sortable": "custom"
    },
    on: {
      "on-sort-change": _vm.changeSort,
      "on-selection-change": _vm.changeSelect
    }
  })], 1), _c('Row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType == 'thumb',
      expression: "showType == 'thumb'"
    }],
    attrs: {
      "type": "flex",
      "gutter": 30
    }
  }, _vm._l(_vm.data, function (item, i) {
    return _c('Col', {
      key: i,
      attrs: {
        "lg": 6,
        "md": 12,
        "xs": 12
      }
    }, [_c('Card', {
      staticClass: "oss-card"
    }, [_c('div', {
      staticClass: "content"
    }, [item.isCollect ? _c('div', {
      staticClass: "collect-icon"
    }, [_c('Icon', {
      attrs: {
        "type": "md-star",
        "color": "#ffc60a",
        "size": "18"
      }
    })], 1) : _vm._e(), item.type.indexOf('image') >= 0 ? _c('img', {
      staticClass: "img",
      attrs: {
        "src": item.url
      },
      on: {
        "click": function ($event) {
          return _vm.showPic(item);
        }
      }
    }) : item.type.indexOf('video') >= 0 ? _c('div', {
      staticClass: "video",
      on: {
        "click": function ($event) {
          return _vm.showVideo(item);
        }
      }
    }, [_c('video', {
      staticClass: "cover"
    }, [_c('source', {
      attrs: {
        "src": item.url + '#t=1',
        "preload": "metadata"
      }
    })]), _c('img', {
      staticClass: "play",
      attrs: {
        "src": require("@/assets/icon/play.png")
      }
    })]) : _c('div', {
      staticClass: "other",
      on: {
        "click": function ($event) {
          return _vm.previewFile(item);
        }
      }
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "key"
    }, [_vm._v(_vm._s(item.fkey))]), _c('div', {
      staticClass: "info"
    }, [_vm._v(" 文件类型：" + _vm._s(_vm.util.ellipsis(item.type, 30)) + " 文件大小：" + _vm._s(_vm.util.getFileSize(item.size)) + " 创建时间：" + _vm._s(item.createTime) + " ")])]), _c('div', {
      staticClass: "actions"
    }, [_c('div', {
      staticClass: "btn"
    }, [_c('Tooltip', {
      attrs: {
        "content": "下载",
        "placement": "top"
      }
    }, [_c('Icon', {
      attrs: {
        "type": "md-download",
        "size": "16"
      },
      on: {
        "click": function ($event) {
          return _vm.download(item);
        }
      }
    })], 1)], 1), _c('div', {
      staticClass: "btn"
    }, [_c('Tooltip', {
      attrs: {
        "content": "分享",
        "placement": "top"
      }
    }, [_c('Icon', {
      attrs: {
        "type": "md-share",
        "size": "16"
      },
      on: {
        "click": function ($event) {
          return _vm.share(item);
        }
      }
    })], 1)], 1), _c('div', {
      staticClass: "btn"
    }, [_c('Tooltip', {
      attrs: {
        "content": "重命名",
        "placement": "top"
      }
    }, [_c('Icon', {
      attrs: {
        "type": "md-create",
        "size": "16"
      },
      on: {
        "click": function ($event) {
          return _vm.rename(item);
        }
      }
    })], 1)], 1), _c('div', {
      staticClass: "btn-no"
    }, [_c('Dropdown', {
      on: {
        "on-click": function ($event) {
          return _vm.changeDropDown(item, $event);
        }
      }
    }, [_c('Icon', {
      attrs: {
        "type": "ios-more",
        "size": "24"
      }
    }), _c('DropdownMenu', {
      attrs: {
        "slot": "list"
      },
      slot: "list"
    }, [_c('DropdownItem', {
      attrs: {
        "name": "collect"
      }
    }, [_vm._v(_vm._s(item.isCollect ? "取消收藏" : "收藏"))]), _c('DropdownItem', {
      attrs: {
        "name": "copy"
      }
    }, [_vm._v("复制")]), _c('DropdownItem', {
      attrs: {
        "name": "remove"
      }
    }, [_vm._v("删除")])], 1)], 1)], 1)])])])], 1);
  }), 1), _c('Row', {
    staticClass: "page",
    attrs: {
      "type": "flex",
      "justify": "end"
    }
  }, [_c('Page', {
    attrs: {
      "current": _vm.searchForm.pageNumber,
      "total": _vm.total,
      "page-size": _vm.searchForm.pageSize,
      "page-size-opts": _vm.pageSizeOpts,
      "size": "small",
      "show-total": "",
      "show-elevator": "",
      "show-sizer": ""
    },
    on: {
      "on-change": _vm.changePage,
      "on-page-size-change": _vm.changePageSize
    }
  })], 1)], 1)], 1)], 1), _c('Drawer', {
    attrs: {
      "title": `上传文件至：${_vm.currCate}`,
      "closable": "",
      "width": "500"
    },
    model: {
      value: _vm.uploadVisible,
      callback: function ($$v) {
        _vm.uploadVisible = $$v;
      },
      expression: "uploadVisible"
    }
  }, [_c('Upload', {
    ref: "up",
    attrs: {
      "action": _vm.uploadFileUrl,
      "headers": _vm.accessToken,
      "data": _vm.uploadData,
      "on-success": _vm.handleSuccess,
      "on-error": _vm.handleError,
      "max-size": 5120,
      "on-exceeded-size": _vm.handleMaxSize,
      "before-upload": _vm.beforeUpload,
      "multiple": "",
      "type": "drag"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "20px 0"
    }
  }, [_c('Icon', {
    staticStyle: {
      "color": "#3399ff"
    },
    attrs: {
      "type": "ios-cloud-upload",
      "size": "52"
    }
  }), _c('p', [_vm._v("点击这里或将文件拖拽到这里上传")])], 1)]), _c('div', {
    staticClass: "drawer-footer"
  }, [_c('Button', {
    on: {
      "click": _vm.clearFiles
    }
  }, [_vm._v("清空上传列表")])], 1)], 1), _c('Modal', {
    attrs: {
      "title": _vm.modalTitle,
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "inline": "",
      "rules": _vm.formValidate
    }
  }, [_c('FormItem', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "文件名",
      "prop": "title"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("提交")])], 1)], 1), _c('Modal', {
    attrs: {
      "title": "移动至",
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.moveVisible,
      callback: function ($$v) {
        _vm.moveVisible = $$v;
      },
      expression: "moveVisible"
    }
  }, [_c('fileCategory', {
    staticClass: "fileMoveModal",
    attrs: {
      "showOnly": ""
    },
    on: {
      "on-select": _vm.selectMoveCategory
    }
  }), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.moveVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.handleSubmitMove
    }
  }, [_vm._v("移动至此")])], 1)], 1), _c('Modal', {
    attrs: {
      "title": _vm.videoTitle,
      "width": 800,
      "draggable": ""
    },
    on: {
      "on-cancel": _vm.closeVideo
    },
    model: {
      value: _vm.videoVisible,
      callback: function ($$v) {
        _vm.videoVisible = $$v;
      },
      expression: "videoVisible"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "id": "dplayer"
    }
  }), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('span', [_vm._v("文件类型：" + _vm._s(_vm.util.ellipsis(_vm.file.type, 30)) + " 文件大小：" + _vm._s(_vm.file.msize) + " 创建时间：" + _vm._s(_vm.file.createTime))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };